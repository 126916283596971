<template>
    <div class="podcast-detail-page">
        <HeroBasic
            eyebrow="podcasts"
            :eyebrowMargin="true"
            :imageUrl="hero?.image?.url"
            :heading="title"
            accentColor="grey"
        >
            <template #headerDetails>
                <Typography v-if="contributors.length" class="hosts" as="div" variant="body-display-small-italic">
                    {{ $t('hosted by') }}
                    <template v-for="(person, index) in contributors" :key="person.firstName">
                        <Anchor :url="person.path">{{ getPersonFullName(person) }}</Anchor>
                        <template v-if="index === contributors?.length - 2">, and </template>
                        <template v-else-if="index !== contributors.length - 1">, </template>
                    </template>
                </Typography>

                <RichTextElements v-if="dek" :elements="dek" bodyStyle="serif-large" class="dek" />

                <div class="controls">
                    <Button
                        v-for="(item, index) in podcastNetworks"
                        :key="index"
                        :url="item.subscribeLink?.url"
                        :newTab="isLinkExternal(item.subscribeLink)"
                        iconPosition="left"
                        variant="secondary"
                        class="controls-item podcast-subscribe"
                        :class="item.network"
                    >
                        {{ $t(item.network) }}
                        <template #icon>
                            <Icon :name="item.network" />
                        </template>
                    </Button>
                    <div class="social-share controls-item">
                        <ShareButton />
                        <CopyLinkButton />
                    </div>
                </div>
            </template>

            <template #rightContent>
                <SubscribeBlock
                    v-if="title === 'Carnegie Connects'"
                    class="subscribe-block-parent size-15"
                    :title="title"
                    :hero="hero"
                    :overrideTopText="'Enter your email address to receive invitations to upcoming live broadcasts from'"
                    size="15"
                    :newsletterFormName="'CarnegieConnects'"
                />
                <div v-else class="right podcast-hero-image">
                    <Image :url="hero?.image?.url" class="podcast-hero" />
                </div>

            </template>
        </HeroBasic>
        
        <IndexTier :contentType="contentType" :pageId="id" :hasFilters="false" :limit="50">
            <template #results="{ results, nextPage }">
                <template v-if="!nextPage || nextPage === 2">
                    <PodcastEpisodeListItem :podcastEpisode="results[0]" class="result" :isPrimary="true" />

                    <template v-for="(result, resultIndex) in results">
                        <PodcastEpisodeListItem
                            v-if="resultIndex !== 0"
                            :key="resultIndex"
                            :podcastEpisode="result"
                            class="result"
                        />
                    </template>
                </template>

                <template v-else>
                    <PodcastEpisodeListItem
                        v-for="(result, resultIndex) in results"
                        :key="resultIndex"
                        :podcastEpisode="result"
                        class="result"
                    />
                </template>
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
const props = defineProps({
    contributors: {
        type: Array,
    },
    dek: {
        type: Object,
    },
    hero: {
        type: Object,
    },
    id: {
        type: String,
    },
    title: {
        type: String,
    },
    contentType: {
        type: String,
        default: '',
    },
    podcastNetworks: {
        type: Object,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
    syndications: {
        type: Object,
    },
});

const headerAttributesStore = useHeaderAttributesStore();
const { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics } = useGTMContent('syndication', props);
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: 'Podcast Homepage',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'grey' });
});
</script>

<style lang="scss" scoped>
.podcast-hero-image {
    @include square(15rem);

    img {
        height: 100%;
    }
}

.name,
.hosts,
.dek {
    max-width: 77rem;
}

.hosts {
    margin-bottom: 1rem;
}

.hosts a {
    color: color(noir, default, 0.9);
    text-decoration: underline;
    text-decoration-color: color(teal);
}

.hosts,
.dek {
    color: color(noir, default, 0.6);
}

.controls {
    margin-top: vertical-space(1);
    display: flex;
    gap: 0.8rem;
    align-items: center;

    @include media-query(phone) {
        display: block;
        a {
            margin-bottom: 1em;
        }
    }
}

.social-share {
    margin-inline-start: 0.8rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
}

.right {
    display: none;

    @include media-query(full) {
        display: block;
    }
}

.result {
    border-bottom: $border;
}

@include media-query(mobile) {
    :deep(.layout) {display: grid}
}
</style>
